import { ChangeEvent, FormEvent, useState } from "react";
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  theme,
  Image,
  Input,
  Button,
  Flex,
  HStack,
  Link,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import logo from "../assets/logo.png";
import { useMembers } from "../hooks/useMembers";
import { GreenCheckMark } from "../components/GreenCheckMark";
import { RedCross } from "../components/RedCross";

export const App = () => {
  const [lastname, setLastname] = useState("");

  const {
    isLoading,
    data: members,
    refetch: checkMember,
  } = useMembers({ lastname, initFetch: false });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastname(e.currentTarget.value);
  };

  const handleCheckMember = () => {
    checkMember();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkMember();
  };

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Image
              boxSize="150px"
              borderRadius={5}
              src={logo}
              alt="ФАіС України"
            />
            <Box>
              <Text>
                Система онлайн оплати членських внесків до ФАіС України
              </Text>
              <Text fontSize="md">
                Будь-ласка, введіть своє прізвище, щоб перевірити наявність
                оплати
              </Text>
            </Box>
            <form onSubmit={handleSubmit}>
              <Flex>
                <Input
                  placeholder="Прізвище"
                  size="lg"
                  mr={4}
                  value={lastname}
                  onChange={handleChange}
                />
                <Button
                  colorScheme="blue"
                  px={10}
                  onClick={handleCheckMember}
                  disabled={!lastname}
                  isLoading={isLoading}
                  size="lg"
                >
                  Перевірити
                </Button>
              </Flex>
            </form>
            {members?.length && (
              <HStack spacing="30px">
                {members.map(({ firstname, lastname, fee_paid, unit }, i) => (
                  <Box borderWidth="1px" borderRadius="lg" p={5} key={i}>
                    <Text fontSize="sm">Ім&apos;я: {firstname}</Text>
                    <Text fontSize="sm">Прізвище: {lastname}</Text>
                    <Text fontSize="sm">
                      Внесок сплачено:{" "}
                      {fee_paid == "true" ? <GreenCheckMark /> : <RedCross />}
                    </Text>
                    <Text fontSize="sm">ВП: {unit}</Text>
                  </Box>
                ))}
              </HStack>
            )}
            {members?.length === 0 && (
              <Box>
                <Text fontSize="sm">
                  Вас немає в базі даних, але ви можете в будь-який момент{" "}
                  <Button variant="link">
                    <Link
                      href={process.env.REACT_APP_MEMBERS_DB_URL}
                      target="_blank"
                    >
                      зареєструватись
                    </Link>
                  </Button>
                  .
                </Text>
              </Box>
            )}
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
