import axios from "axios";
import { useQuery } from "react-query";

type GetMembersParams = {
  lastname?: string;
};

type UseMembersParams = GetMembersParams & {
  initFetch?: boolean;
};

type Member = {
  firstname: string;
  lastname: string;
  fee_paid: string;
  unit: string;
};

const getMembers = async ({ lastname }: GetMembersParams) => {
  const { data } = await axios.get<Member[]>("/members", {
    params: {
      lastname,
    },
  });

  return data;
};

export const useMembers = ({ lastname, initFetch }: UseMembersParams) =>
  useQuery<Member[], Error>(
    ["members", { lastname }],
    () => getMembers({ lastname }),
    { enabled: initFetch, keepPreviousData: true }
  );
